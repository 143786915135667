import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import CollectionPage from '~/components/Collection/Page'
import ContentPage from '~/components/Content/Page'
import { useBulkMeter } from '~/hooks/components/use-bulk-meter'
import { useCart } from '@chordcommerce/gatsby-theme-autonomy'

const BULK_DISCOUNT_PROMO_CODE = process.env.GATSBY_BULK_DISCOUNT_PROMO_CODE

const Bulk = ({ data }) => {
  const { collection, page } = data

  const { eligibleToBulkOrder } = useBulkMeter()
  const { addPromoCode } = useCart()

  useEffect(() => {
    const addBulkPromoCode = async () =>
      await addPromoCode({ promoCode: BULK_DISCOUNT_PROMO_CODE })

    if (BULK_DISCOUNT_PROMO_CODE && eligibleToBulkOrder) {
      addBulkPromoCode()
    }
  }, [addPromoCode, eligibleToBulkOrder])

  return (
    <Layout includeNotifications={false}>
      <Metadata />
      <CollectionPage collection={collection} isBulk={true} />
      <ContentPage page={page} />
    </Layout>
  )
}

export default Bulk

export const query = graphql`
  query BulkPage($locale: String) {
    collection: contentfulCollection(
      slug: { eq: "shop-bulk" }
      node_locale: { eq: $locale }
    ) {
      ...CollectionDetailsFragment
    }
    page: contentfulPage(slug: { eq: "bulk" }, node_locale: { eq: $locale }) {
      ...ContentPageFragment
    }
  }
`
